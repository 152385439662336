.recentChip {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.recentChip::-webkit-scrollbar {
  display: none;
}

.noScrollBarX {
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.noScrollBarX::-webkit-scrollbar {
  display: none;
}

.noScrollBar {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.noScrollBar::-webkit-scrollbar {
  display: none;
}

.qna-content-txt {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000;
}

.qna-answer-txt {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000;
}

.ellipsis-2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blink {
  animation: blink-effect 1s;
}
@keyframes blink-effect {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.btn-ai_stop {
  padding: 0px 8px !important;
}

.ai-agent-list {
  min-height: 32px !important;
}